export const auth = {
  page: {
    unAuthorized: {
      title: 'todo: 待產品更新文案 title',
      description: 'todo: 待產品更新文案 description',
    },
  },
};

export default auth;
