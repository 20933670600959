export const productFeedManager = {
  app: {
    name: 'Product Feed Manager',
  },
  tableHeader: {
    channelName: 'Product feed title',
    lang: 'Language',
    updateTime: 'Last updated',
  },
  emptyList: {
    title: 'Customize your product feeds for easy management!',
    description: 'Create and manage your customized product feed.',
  },
  title: {
    channelEntry: 'Add feed',
  },
  tips: {
    chanelLimit: "You've reached the feed limit.",
  },
  popover: {
    productFeedManagerFaq: {
      title: 'Learn more',
      content:
        'The update times for product feeds are different across channels. For more details, please refer to the FAQ.',
      function: 'For more info, please refer to:',
    },
  },
  channel: {
    title_google: 'Google',
    title_criteo: 'Criteo',
  },
  channelListPopup: {
    title: 'Select Channel',
    description:
      'Currently, you can create only one product feed per channel. Please select the channel to create the feed.',
    chanelDescription: {
      google:
        'Supports product feeds for Pinterest, TikTok, as well as various Martech and marketing channels (e.g., Omnichat, Influenxio, Insider, Rosetta, etc.).',
      criteo: 'The ultimate tool for retargeting across ad networks.',
    },
  },
  form: {
    title: 'Product Upload Rules',
    description:
      'Use different conditions to create generation rules for product feed.',
    valid: {
      required: '{{filedName}} is required',
    },
    fields: {
      title: {
        title: 'Product feed title',
        placeholder: 'Up to 50 characters',
      },
      withOutOfStockProduct: {
        title: 'Select product sales status',
        options: {
          all: 'Upload products with and without inventory',
          part: 'Only upload products with inventory or available for sale',
        },
      },
      withPreorderProduct: {
        title: 'Add pre-order products',
      },
      categoryIds: {
        title: 'Select product categories (optional)',
        placeholder: 'Please select',
      },
      useBarcode: {
        title: 'Use system unique product IDs or product SKUs?',
        options: {
          number: 'Use product SKUs',
          customNumber: 'Use system unique product IDs',
        },
      },
      mainProductImageSelect: {
        title: 'Select main product image ',
        options: {
          first: 'First image',
          second: 'Second image',
          last: 'Last image',
        },
      },
      variantProductImageSelect: {
        title: 'Select product variation image',
        options: {
          main: 'Main product image',
          sku: 'Variation image',
        },
      },
      joinMpnAndBarcode: {
        title: 'Select target language',
      },
      language: {
        title: 'Select target language',
        options: {
          zhHant: '繁體中文',
          zhHans: '简体中文',
          en: 'English',
        },
      },
    },
    footer: {
      save: 'Save',
      description: `You're almost there! Click "Save" to confirm your changes.`,
    },
    leavePopup: {
      title: 'Unsaved Changes',
      description:
        'If you leave before saving, the changes you made will be lost. Suggest you to save first.',
    },
    productOverview: {
      title: 'Product Overview',
      description:
        'View products that match the conditions on the left. Note: This section only lists products that will be included in the next product feed update and does not reflect real-time data.',
      entry: 'View products',
      popup: {
        title: 'View products',
        footer: {
          total: 'contains {{n}} product(s)',
        },
      },
      table: {
        column: {
          name: 'Name',
          variation: 'Variation',
        },
      },
      empty: {
        title: 'No matching products',
        description: 'Please adjust your product upload rules and try again.',
      },
    },
  },
};

export default productFeedManager;
